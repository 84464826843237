html {
  box-sizing: border-box;
  font-family: 'helvetica neue';
  font-size: 20px;
  font-weight: 200;
}

body {
  /*background: url("http://www.dieselstation.com/wallpapers/albums/Volkswagen/Polo-BlueGT-2013/Volkswagen-Polo-BlueGT-2013-widescreen-33.jpg");*/
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
          /*no-repeat*/
  background-color: #09062c ;
  color: darkgray;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.container {
  background: darkgray;
  color: #09062c;
  padding: 10px;
  border-radius: 10px;
}

.start-page {
  background: url("https://www.automobilesreview.com/gallery/2012-volkswagen-polo-r-line/2012-volkswagen-polo-r-line-01.jpg");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 25%;
  width: 200px;
  height: 200px;
}

select, input{
  /*background: #09062c;*/
  /*color: darkgray;*/
  display: block;
  padding: 5px 0;
  margin: 0 0;
}

label {
  margin: 5px 0 0 0;
  padding: 0 0 3px 0;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login {
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flex-element {
  flex:1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  transition: transform 0.5s;
}

/*.flex-element > * {*/
/*  margin: 0;*/
/*  width: 100%;*/
/*  transition: transform 0.5s;*/
/*  flex: 1 0 auto;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

.image {
  background-image: url("https://plumbr.io/app/uploads/2015/01/thread-lock.jpeg");
  border-radius: 50%;
  overflow: hidden;
  margin: 10px;
}

.github {
  width: auto;
  height: 50px;
  margin:0;
  padding:0;
  border-radius: 5%;
  background: white;
  border-color: white;

}

